// TODO: this should not be necessary.  leaf-validation-message is added via validationcontroler and all form comonents
// use scopedregistry mixin to pull in leaf-validation-message but it does not work. Look into this after form controls
// have been re written.
import '@cigna/dcws_leaf-web-components/components/validation-message/validation-message.js';
import { Loader } from '@cigna/dcws_leaf-web-components/utilities/component-loader.js';
import type { RegisteredComponent } from '@cigna/dcws_leaf-web-components/utilities/component-loader.js';

const components: Record<string, RegisteredComponent> = {
  'tcg-value-props': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/value-props/value-props.js');
    },
  },
  'tcg-value-prop-heading': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/value-prop-heading/value-prop-heading.js');
    },
  },
  'tcg-value-prop': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/value-prop/value-prop.js');
    },
  },
  'tcg-row': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/row/row.js');
    },
  },
  'tcg-quote': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/quote/quote.js');
    },
  },
  'tcg-promo': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/promo/promo.js');
    },
  },
  'tcg-infinity-graphic': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/infinity-graphic/infinity-graphic.js');
    },
  },
  'tcg-hero': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/hero/hero.js');
    },
  },
  'tcg-header-dropdown': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/header-dropdown/header-dropdown.js');
    },
  },
  'tcg-header': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/header/header.js');
    },
  },
  'tcg-footer': {
    register() {
      return import('@cigna/dcws_leaf-web-components/tcg-components/footer/footer.js');
    },
  },
  // statically imported above
  // 'leaf-validation-message': {
  //   register() {
  //     return import('@cigna/dcws_leaf-web-components/components/validation-message/validation-message.js');
  //   },
  // },
  'leaf-textarea': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/textarea/textarea.js');
    },
  },
  'leaf-tag': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tag/tag.js');
    },
  },
  'leaf-tab': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tabs.js');
    },
  },
  'leaf-tab-section': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tab-section.js');
    },
  },
  'leaf-tab-item': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/tabs/tab-item.js');
    },
  },
  'leaf-spinner': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/spinner/spinner.js');
    },
  },
  'leaf-social-link': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/social-link/social-link.js');
    },
  },
  'leaf-select-option': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/select-option/select-option.js');
    },
  },
  'leaf-select': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/select/select.js');
    },
  },
  'leaf-radiogroup': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/radiogroup/radiogroup.js');
    },
  },
  'leaf-radio': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/radio/radio.js');
    },
  },
  'leaf-progress-bar': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/progress-bar/progress-bar.js');
    },
  },
  'leaf-multimedia-brightcove': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/multimedia-brightcove/multimedia-brightcove.js');
    },
  },
  'leaf-multimedia-limelight': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/multimedia-limelight/multimedia-limelight.js');
    },
  },
  'leaf-modal': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/modal/modal.js');
    },
  },
  'leaf-list': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/list/list.js');
    },
  },
  'leaf-label': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/label/label.js');
    },
  },
  'leaf-input': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/input/input.js');
    },
  },
  'leaf-icon': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/icon/icon.js');
    },
  },
  'leaf-group': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/group/group.js');
    },
  },
  'leaf-flex-container': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/flex-container/flex-container.js');
    },
  },
  'leaf-divider': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/divider/divider.js');
    },
  },
  'leaf-datetime': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/datetime/datetime.js');
    },
  },
  'leaf-checkgroup': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/checkgroup/checkgroup.js');
    },
  },
  'leaf-checkbox': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/checkbox/checkbox.js');
    },
  },
  'leaf-card': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/card/card.js');
    },
  },
  'leaf-button-link': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/button-link/button-link.js');
    },
  },
  'leaf-button': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/button/button.js');
    },
  },
  'leaf-breadcrumbs-item': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/breadcrumbs-item/breadcrumbs-item.js');
    },
  },
  'leaf-breadcrumbs': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/breadcrumbs/breadcrumbs.js');
    },
  },
  'leaf-backdrop': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/backdrop/backdrop.js');
    },
  },
  'leaf-alert': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/alert/alert.js');
    },
  },
  'leaf-accordion-panel-inline': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion-panel-inline/accordion-panel-inline.js');
    },
  },
  'leaf-accordion-panel': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion-panel/accordion-panel.js');
    },
  },
  'leaf-accordion': {
    register() {
      return import('@cigna/dcws_leaf-web-components/components/accordion/accordion.js');
    },
  },
};

export const loadComponents = (logger?: Console) => {
  const loader = new Loader(components, logger);

  const stopLoader = () => {
    // <meta name="autoloader" content="stopafterload"> = stop autoloader after initial load
    const loaderMeta = document.querySelector('meta[name="autoloader"],meta[name="component-loader"]');
    if (loaderMeta?.getAttribute('content') === 'stopafterload') {
      loader.stop();
    }
  };

  let componentsDiscovered = false;
  document.addEventListener('DOMContentLoaded', () => {
    loader.discover(document.body, true).then(stopLoader);
    componentsDiscovered = true;
  });

  if (!componentsDiscovered) {
    loader.discover(document.body, true).then(stopLoader);
  }
};
